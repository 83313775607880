<template>
	<b-modal ref="modalLocalisation" @hidden="resetModal" @shown="modalShown">
		<template slot="modal-header" slot-scope="{ close }">
			<h2 class="mx-auto my-auto">{{ $t('monte.stock_semence.reorganiser') }}</h2>
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'times-circle']" /></i>
		</template>
		<template slot="default">
			<div class="row">
				<draggable class="col-12 list-group" group="colonne" :list="children">
                    <div class="list-group-item  d-flex align-items-center" v-for="el in children" :key="el.semencelocalisation_id" @dragend="orderChildren">
						<font-awesome-icon :icon="['far', 'sort']" class="mr-3" /> {{ el.semencelocalisation_libelle }}
                    </div>
                </draggable>
			</div>

		</template>

		<template slot="modal-footer" class="justify-content-center">
			<b-button variant="primary" @click="saveReorganize" :disabled="processing">
				<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
				{{ $t('global.enregistrer') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script type="text/javascript">
    import StockSemence from '@/mixins/StockSemence.js'	

	export default {
		name: 'ModalReorganizeLocSemence',
		props: ['localisation', 'refresh_table'],
		mixins: [StockSemence],
		data () {
			return {
				processing: false,
				children: []
			}
		},

		methods: {
			async init_component() {
				this.children = await this.localisation.children
			},

			openModal() {
				this.init_component()
				this.$refs.modalLocalisation.show()
			},

			async saveReorganize() {
				this.processing = true
				await this.saveLocalisationSemenceOrder(this.children)
				this.processing = false
				this.resetModal()
				this.$refs.modalLocalisation.hide()
				this.successToast('toast.info_save_succes')
				this.$emit('update:refresh_table', !this.refresh_table)
			},

			resetModal() {
                this.processing = false
            },

            modalShown() {
            	this.init_component()
            },

            orderChildren() {
				for (var key in this.children) {
					this.children[key].semencelocalisation_order = key
				}
				this.children.sort(function(a, b){return a.semencelocalisation_order-b.semencelocalisation_order})
            }
		},

		components: {
			draggable: () => import('vuedraggable')
		}
	}

</script>